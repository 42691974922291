import * as React from "react";
import "./MainBlogCardTrends.css"

const MainBlogCardTrends = ({image, title, timestamp, paragraph}) => {

return (
    <div className="blogCardTrends">
      <img className="imageTrends" src={image} alt="" />

      <div className="blog__infoTrends">
      <h3>{title}</h3>
       </div>
        <p className="timeStamp">{timestamp}</p>
     
           <div className="blog__textTrends">
          <p>{paragraph}</p>  
          </div>

    </div>


    );   

}

export default MainBlogCardTrends