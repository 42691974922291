import * as React from "react";
import Header from "./Header";
import "./App.css";
import PerspectiveNavigation from "./PerspectiveNavigation";
import BlogCard from "./BlogCard"
import HomeImage from "../images/Rectangle378.png"
import HomeImageSecond from "../images/Rectangle490.png"
import Trends from "./Trends";
import {graphql} from "gatsby"


const App = () => {
  return(
    <div className="app">
       <Trends/>
          </div>
  );
};

export default App;
