import * as React from "react"
import "./TrendsNavigation.css"

const TrendsNavigation = () => {
    return(
    <div className="backgroundImageTrends">
    <div className="title">
      <h1 >Trends&nbsp; &nbsp;&nbsp;</h1>
    <p className="p_tag"> &nbsp;Home &nbsp; {">"} &nbsp;  Trends</p>
  </div>
  </div> 
    );
}

export default TrendsNavigation