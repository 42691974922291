import * as React from "react";
import Header from "./Header";
import "./Trends.css";
import TrendsNavigation from "./TrendsNavigation";
import {Link} from "gatsby";
import MainBlogCardTrends from "./MainBlogCardTrends";
import TaxImage from "../images/Tax_image.png";
import HomeImage from "../images/Home_image.png";
import BlogRow from "./BlogRow";
import Footer from "./Footer";

const Trends = () => {
  return(
  <div className="app">
  <Header/>
  <TrendsNavigation/>
  <div>
        <div className="button_divTrends">
            <Link className="PerspectiveNavigator" to="/Perspective">
          <button  class="button_trends ">
            Perspective
          </button>
          </Link>
          <button
          
            class="button2_trends "
          >
            &nbsp;&nbsp;&nbsp;Trends&nbsp;&nbsp;&nbsp;
          </button>
        </div>
      
       
      </div>
  <div className="main_blog_trends">
      {/* <MainBlogCardTrends 
      image={TaxImage}
      title="5 Problems Faced By NRIs When Buying Property In India."
      timestamp="02/02/2021"
      paragraph="As much as the advent of technology 
        has made it easier to
        conduct business across 
        time zones when it comes to matters involving heavy money,
        nothing beats the security of proximity.
         And this is primarily what plagues an NRI’s property investment in India.
      "          
      />
       <MainBlogCardTrends 
      image={HomeImage}
      title="Embrace Quality Lifestyle and #Livemore in Shantigram with 
      Adani Realty"
      timestamp="02/02/2021"
      paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
      "          
      />
     
      
      </div>
      <div className="TrendsBlogRow">
          <BlogRow  
             image={HomeImage}
             title="Embrace Quality Lifestyle and #Livemore in Shantigram with Adani Realty"
             timestamp="02/02/2021"
             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
          />
          <BlogRow  
             image={TaxImage}
             title="Embrace Quality Lifestyle and #Livemore in Shantigram with Adani Realty"
             timestamp="02/02/2021"
             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
          />
           <BlogRow  
             image={HomeImage}
             title="Embrace Quality Lifestyle and #Livemore in Shantigram with Adani Realty"
             timestamp="02/02/2021"
             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
          />
           <BlogRow  
             image={TaxImage}
             title="Embrace Quality Lifestyle and #Livemore in Shantigram with Adani Realty"
             timestamp="02/02/2021"
             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
          />
           <BlogRow  
             image={HomeImage}
             title="Embrace Quality Lifestyle and #Livemore in Shantigram with Adani Realty"
             timestamp="02/02/2021"
             description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."
          /> */}
           <h1> Coming Soon.. </h1>
      </div>
      <hr/>
      <Footer/>
  </div>
  );
}

export default Trends