import * as React from "react"
import "./index.css"
import App from "./App.js"

// markup
const IndexPage = () => {
  return (
     <div>
       <App/>
     </div>
  )
}

export default IndexPage
